import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import AppContext from "./appContext"

import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

import { makeStyles } from "@material-ui/core/styles"
import NoSsr from "@material-ui/core/NoSsr"
import LinearProgress from "@material-ui/core/LinearProgress"

import SEO from "./seo"
import Footer from "./footer"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const InitialState = { open: false, isLoading: false }

function reducer(state, action) {
  switch (action.type) {
    case "open":
      return {
        ...state,
        open: true,
        severity: action.severity,
        message: action.message,
      }
    case "close":
      return { ...state, open: false }
    case "loader":
      return { ...state, isLoading: action.isLoading }
    default:
      throw InitialState
  }
}

const useStyles = makeStyles(() => ({
  loader: {
    position: "fixed",
    width: "100%",
    zIndex: 1600,
    top: 0,
  },
}))

const Layout = ({ children, seo = {} }) => {
  const {
    site: {
      siteMetadata: {
        socialMediaNumber: { email },
        UNP,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            socialMediaNumber {
              email
            }
            UNP
          }
        }
      }
    `
  )

  const styles = useStyles()

  const [state, dispatch] = React.useReducer(reducer, {
    open: false,
    isLoading: false,
  })

  const handleClose = React.useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return
      }

      dispatch({ open: false, type: "close" })
    },
    [dispatch]
  )

  return (
    <>
      <SEO {...seo} />
      <main>
        {state.isLoading && (
          <div className={styles.loader}>
            <LinearProgress></LinearProgress>
          </div>
        )}
        <AppContext.Provider value={dispatch}>{children}</AppContext.Provider>
        <NoSsr>
          <Snackbar
            open={state.open}
            autoHideDuration={10000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={state.severity}>
              {state.message}
            </Alert>
          </Snackbar>
        </NoSsr>
      </main>
      <footer>
        <Footer email={email} UNP={UNP} />
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
